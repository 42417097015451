import { Link } from "react-router-dom"

type props = {
    pageTitle : string
    subTitle? : string
    parentPage? : { title : string, link : string}
    description? : string
    bgImage? : string
    bgImagePosition? : "top" | "center" |"bottom" | ""
}

export default function Breadcrumb({pageTitle, subTitle, parentPage, description, bgImage = "/images/ifim-gabon-pexels-matheus-bertelli-4558717.jpg", bgImagePosition = "bottom"} : props){
    return (
        <section className={`pt-20 lg:pt-10 relative h-fit bg-cover bg-${bgImagePosition} after:bg-black after:bg-opacity-30 after:absolute after:inset-0`} style={{ backgroundImage : `url(${bgImage})` }}>
            {/* <img src={bgImage} alt="" className=" absolute z-10 inset-0 object-cover object-center " /> */}
            <div className="relative z-20 container mx-auto px-4 py-12 md:px-0 md:py-24 flex justify-center gap-6 md:gap-0">                
                <div className="flex-1">
                    { subTitle && <h3 className="text-xl text-center text-white font-semibold mb-4">{subTitle}</h3>}
                    <h1 className="text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-bold text-center max-w-2xl mx-auto text-secondary">{pageTitle}</h1>
                    <p className="text-center max-w-xl mx-auto my-8 text-white">{description}</p>
                    {/* { description && <p className="text-center max-w-xl mx-auto my-8 text-white">{description}</p>} */}
                    <div className=" w-fit bg-primary bg-opacity-80 py-5 px-4 md:px-12 rounded-lg shadow-sm mx-auto flex items-center justify-center gap-4">
                        <Link to="/" className="text-secondary hover:text-primary-deep hover:underline"><strong>Accueil</strong></Link>
                        { parentPage && (
                            <>
                                <i className="fas fa-angle-right text-secondary" />
                                <Link to={parentPage.link} className="text-center"><strong className="font-semibold text-secondary">{parentPage.title}</strong></Link>
                            </>
                        )}
                        <i className={`${parentPage ? "hidden" : ""} fas fa-angle-right text-secondary`} />
                        <strong className={`${parentPage ? "hidden" : ""} font-semibold text-white`}>{pageTitle}</strong>
                    </div>
                </div>
            </div>
        </section>
    )
}