import { useEffect } from "react";
import Breadcrumb from "../components/breadcrumb";
import Container from "../components/container";

export default function MotDuFondateurPage() {
    useEffect( () => {
        document.title = "IFIM Gabon | Mot du fondateur"
    }, [])
    return (
        <>
            <Breadcrumb pageTitle="Mot du fondateur" description="" bgImage="/images/ifim-gabon-pexels-matheus-bertelli-4558717.jpg" bgImagePosition="bottom" />
            
            {/* section mot du fondateur ends */}
            <section className="py-28">
                <Container className="grid lg:grid-cols-2 min-h-[100vh]">
                    <div className="hidden md:block h-full w-full">
                        <img src="/images/ifim-gabon-pexels-kampus-production-5940835.jpg" alt="" className="h-full w-full object-cover object-bottom rounded-md" />
                    </div>
                    <div className="lg:pl-12 flex flex-col gap-y-4 text-justify  ">
                        <img src="/images/ifim-gabon-pexels-kampus-production-5940835.jpg" alt="" className="lg:hidden h-72 w-full object-cover object-center rounded-md" />
                        <p className="">
                            Les établissements supérieurs privés sont de plus en plus présents aux côtés des établissements supérieurs publics, l’objectif étant d’apporter une réponse efficace à l’adéquation « Formation-Emploi».
                        </p>
                        <p className="">
                            Cependant, depuis une dizaine d’année, nous nous rendons à l’évidence que le nombre de diplômés, au sortir des différents établissements supérieurs, est de plus en plus élevé chaque année. Ce qui rend les opportunités d’emploi moins accessibles.
                        </p>
                        <p className="">
                            De ce fait, il devient opportun de « croire en soi» en pensant à des formations professionnalisantes de niveau supérieur. Il revient à chaque promoteur d’établissement privé supérieur de s’interroger sur l’objectif réel de son action, afin d’œuvrer collectivement pour renverser la courbe.
                        </p>
                        
                        <p className="">
                            Nous pouvons partir d’un questionnement qui se veut constructif :
                        </p>

                        <ul className=" text-base mt-4 list-none divide-y-8 divide-transparent">
                            <li className="flex items-start gap-2">
                                <i className="text-xl 2xl:text-3xl text-primary fa-solid fa-square-check" />
                                <span className="italic">
                                    De quel modèle de réussite s’inspire les jeunes diplômés gabonais aujourd’hui ? Ce modèle, s’il existe réellement, donne-t-il l’assurance d’une génération pouvant relever des défis ?
                                </span>
                            </li>
                            <li className="flex items-start gap-2">
                                <i className="text-xl 2xl:text-3xl text-primary fa-solid fa-square-check" />
                                <span className="italic">
                                    Aujourd’hui, considérant les formations proposées dans nos différentes institutions d’enseignement supérieures et professionnelles, donnons-nous les moyens suffisants, permettant à cette génération de montrer ce dont elle est capable ? d’exprimer son talent ou de se mettre en valeur ?
                                </span>
                            </li>
                        </ul>
                        <p>
                            Les jeunes gabonais comme tous ceux de leur génération, sont dotés de talents. Une fois diplômés, ils sont remplis de motivation et ouverts à toute activité qui leur permettrait de vivre décemment. C’est dans ce contexte que vient s’inscrire l’<span className="font-semibold">Institut Facultaire d’Informatique et de Management</span>.
                        </p>
                        <div className="text-primary text-left">
                            <h1 className="text-2xl  md:text-3xl font-bold ">Elie NTOUMBA TCHYMANGA,</h1>
                            <h3 className="italic">père fondateur de IFIM</h3>
                        </div>
                    </div>
                </Container>
            </section>
            {/* section mot du fondateur ends */}
        </>
    )
};
