import { createContext, ReactNode } from 'react';
import LayoutContextProvider from './layout.context';

type props = {

}
export const GlobalContext = createContext<props>({})
export default function GlobalContextProvider({children} : {children : ReactNode}) {
    
    
    return (
        <GlobalContext.Provider value={{}}>
            <LayoutContextProvider>
                {children}
            </LayoutContextProvider>
        </GlobalContext.Provider>
    )
};
