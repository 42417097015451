const FORMATIONS = [
    {
        slug : "webdesign",
        category : "Informatique",
        name : "Webdesign",
        objectif : (
            <>
                La Licence Professionnelle en <span className="italic text-primary font-semibold">Informatique et Management option Web design</span> forme des experts de la conception graphique des sites internet, 
                capables aussi de proposer une charte graphique à leurs clients. Il travaille sur un design adapté à la visibilité de l’entreprise, en adéquation 
                avec les axes de communication de celle-ci. Il rend le site attractif pour les internautes ciblés avec une image engageante et facilement identifiable.
            </>
        ),
        skillsAndOutcome : {
            skills : {
                datas : [
                    "Concevoir des maquettes des sites internet répondant aux besoins des clients et aux contraintes de navigation et en fonction des outils (mobiles, ordinateurs, télévisions, etc.)",
                    "utilisation de l’outil graphique",
                    "mettre en place l’arborescence et l’architecture des sites répondant aux contraintes techniques et aux besoins des utilisateurs",
                    "définir les supports de communication avec les visiteurs : la gestion du service d’e-mailing, des newsletters comme des liens internet lui incombent",
                    "s’assure du bon fonctionnement et de la mise à jour."
                ]
            },
            outcome : {
                excerpt : "Au terme de sa formation, le diplômé pourra exercer comme entrepreneur autonome et occuper un poste suivant au sein des entreprises, des sociétés :",
                datas : [
                    "Web designer dans les agences web, les entreprises de service numérique",
                    "Freelance"
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de Licence Professionnelle en Informatique et Management option Web Design est conçu, principalement, pour les étudiants titulaires d’un baccalauréat toutes séries confondues. 
                Toutefois, l’admission directe en L3 Web Design est conditionnée par l’obtention d’un diplôme Bac+2 en Informatique ou par la validation d’un parcours de 120 crédits en Mathématiques ou dans 
                les domaines de la <span className="text-primary italtic">communication visuelle, du design graphique, de l’audiovisuel, multimédia, art et technologie.</span>
            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel qui 
                représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            breadcrumb : "/images/ifim-gabon-black-keyboard.jpg",
            breadcrumbPosition : "center",
            skillAndOutcome : "/images/ifim-gabon-medium-shot-smiley-woman-presenting.jpg",
            conditions : "/images/ifim-gabon-group-four-african-girls-posed-outdoor.jpg"
        }
    },
    {
        slug : "comunity-management",
        category : "Management",
        name : "Community Management",
        objectif : (
            <>
                La Licence Professionnelle en <span className="italic text-primary font-semibold">Informatique et Management option Community Manager</span> a pour objectif de former des 
                salariés ou des travailleurs indépendants, à l’utilisation des réseaux sociaux à des fins professionnels. Il développe et gère l’image d’une organisation, d’une entreprise, 
                d’une association, sur les réseaux sociaux.
            </>
        ),
        skillsAndOutcome : {
            skills : {
                datas : [
                    "améliorer la visibilité d'une entreprise sur internet et les réseaux sociaux",
                    "organiser son travail autour d'une stratégie de communication précise et définie",
                    "créer une communauté, d’interagir avec elle afin d’instaurer une relation durable et privilégiée",
                    "identifier puis fédérer un groupe d’internautes autour de sujets communs proches de l’activité de l’entreprise ou organisation qu’il représente",
                    "veiller à ce que les échanges demeurent actifs, et créer des contenus qui auront de la valeur pour sa communauté",
                    "organiser des opérations spéciales (concours, challenges…) et des événements",
                    "participer à la rédaction de contenus viraux, à la production de contenus photos et vidéos",
                    "créer des groupes de discussions ou des hubs sur des sujets innovants ou tendance",
                    "booster des sites, blogs, réseaux sociaux, forums et autres plateformes et médias externes... qui parlent de son entreprise, pour participer au dialogue mais aussi le modérer",
                    "faire appliquer les règles de bonne conduite au sein de sa communauté."
                ]
            },
            outcome : {
                excerpt : "Au terme de sa formation, le diplômé pourra exercer comme entrepreneur autonome et occuper un poste suivant au sein des entreprises, des sociétés :",
                datas : [
                    "Créer son entreprise",
                    "social media manager",
                    "chef de projet digital",
                    "responsable communication web",
                    "responsable éditorial web"
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de licence professionnelle en informatique et management option Community Manager est conçu, principalement, pour les étudiants titulaires d’un baccalauréat toutes séries
                 confondues. Toutefois, l’admission directe en L3 Community Manager est conditionnée par l’obtention d’un diplôme Bac+2 ou par la validation d’un parcours de 120 crédits en Gestion.
            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel 
                qui représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            breadcrumb : "/images/ifim-gabon-group-five-african-college-students-spending-time-together-campus-university.jpg",
            breadcrumbPosition : "top",
            skillAndOutcome : "/images/ifim-gabon-happy-students.jpg",
            conditions : "/images/ifim-gabon-group-four-african-girls-posed-outdoor.jpg"
        }
    },
    {
        slug : "commercial-web",
        category : "Management",
        name : "Commercial Web",
        objectif : (
            <>
                Le commercial Web est chargé de la vente des produits ou des services en lien avec le Web qui sont proposés par son entreprise. 
                Il doit pour cela avoir de bonnes connaissances sur ces produits afin de pouvoir les présenter en détail aux clients potentiels sur le Web.
            </>
        ),
        skillsAndOutcome : {
            skills : {
                excerpt : "Le commercial Web est un vendeur et un négociateur mais également un expert et un conseiller. A la fin de la formation, l’étudiant doit être capable de :",
                datas : [
                    "connaître les outils d’analyse marketing des produits et services qu'il est amené à vendre sur le web afin de pouvoir répondre aux questions de ses interlocuteurs",
                    "être au fait des techniques d’analyse du comportement des consommateurs afin d’être capable de lui conseiller le produit le plus adapté en fonction de ses besoins, et de lui expliquer toutes les spécificités du produit ou du service sur le web",
                    "faire évoluer un portefeuille de clients des entreprises",
                    "servir d’interface entre le marché et l’entreprise afin de détecter de nouveaux besoins susceptibles d’être satisfaits",
                    "remplir les objectifs en termes de vente et de développement du portefeuille clients sur le web",
                    "négocier, convaincre et susciter l’acte d’achat"
                ]
            },
            outcome : {
                datas : [
                    "consultant pour une entreprise web, une agence web ou une agence de communication, dans une PME",
                    "employé dans le service communication ou marketing, en web Agency",
                    "en free-lance",
                    "responsable communication web",
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de licence professionnelle en informatique et management option <span className="text-primary italic font--semibold">Commercial Web</span> est conçu, principalement, 
                pour les étudiants titulaires d’un baccalauréat toutes séries confondues. Toutefois, l’admission directe en L3 Commercial Web est conditionnée par l’obtention d’un diplôme Bac+2 en 
                Gestion et en Informatique ou par la validation d’un parcours de 120 crédits en Gestion ou dans les métiers de l’industrie.
            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel 
                qui représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            // breadcrumb : "/images/ifim-gabon-pexels-kampus-production-5940713.jpg",
            skillAndOutcome : "/images/ifim-gabon-pexels-monstera-9431442.jpg",
            conditions : "/images/ifim-gabon-young-students-studying.jpg"
        }
    },
    {
        slug : "administration-et-gestion-des-bases-donnees",
        category : "Informatique",
        name : "Administration et Gestion des Bases de Données",
        objectif : (
            <>
                La Licence Professionnelle en Informatique et Management option <span className="text-primary italic font-semibold">Administration et Gestion des Bases de Données</span> vise à former des professionnels spécialisés en gestion 
                et administration des bases de données. Ces administrateurs élaborent, optimisent et supervisent une ou plusieurs bases d’information internes à une entreprise.
                <p>
                    L’objectif principal de cette licence professionnelle est de donner au professionnel les compétences dans la maîtrise des méthodes et outils de gestion et d’administration de bases de 
                    données les plus couramment utilisées.
                </p>
            </>
        ),
        skillsAndOutcome : {
            skills : {
                datas : [
                    "Concevoir, développer et administrer des bases de données",
                    "Concevoir et développer des applications de bases de données",
                    "Extraire des données d’une base de données et les effectuer leur migration."
                ]
            },
            outcome : {
                excerpt : "Au terme de sa formation, le diplômé pourra exercer comme entrepreneur autonome et manager ou au sein des entreprises, des sociétés, des administrations publiques ou privées en tant que :",
                datas : [
                    "Développeur d’applications autour des bases de données",
                    "Administrateur de bases de données",
                    "Concepteur de bases de données",
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de licence professionnelle en informatique et management option <span className="text-primary italic font--semibold">Administration et Gestion des Bases de Données</span> est conçu, 
                principalement, pour les étudiants titulaires d’un baccalauréat toutes séries confondues. Toutefois, l’admission directe en L3 Administration et gestion des bases de données est conditionnée par 
                l’obtention d’un diplôme Bac+2 en Informatique ou par la validation d’un parcours de 120 crédits en Mathématiques ou dans les domaines équivalents.

            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel 
                qui représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            breadcrumb : "/images/ifim-gabon-students-paying-attention.jpg",
            skillAndOutcome : "/images/ifim-gabon-pexels-yan-krukov-8199595.jpg",
            conditions : "/images/ifim-gabon-three-african-students-females.jpg"
        }
    },
    {
        slug : "administration-et-securite-des-reseaux-d-entreprise",
        category : "Informatique",
        name : "Administration et Sécurité des Réseaux d'Entreprise",
        objectif : (
            <>
                La Licence Professionnelle en Informatique et Management option <span className="text-primary italic font-semibold">Administration et Sécurité des Réseaux d'Entreprise</span> vise à 
                former des professionnels à la sécurité des systèmes informatiques et à l’administration des réseaux d’entreprise et d’opérateurs, ainsi que de leur donner une double compétence dans 
                la gestion de projets et le management d’équipe.
                    <ul className="mt-4 list-none text-center font-semibold">
                        <li className="w-fit mx-auto flex items-start gap-2">
                            <i className="text-xl 2xl:text-3xl text-secondary fa-solid fa-square-check" />
                            <span className="">
                                Avoir une critique sur la transmission et la sécurité des données d’une entreprise
                            </span>
                        </li>
                        <li className="w-fit mx-auto flex items-start gap-2">
                            <i className="text-xl 2xl:text-3xl text-secondary fa-solid fa-square-check" />
                            <span className="">
                                Concevoir un règlement de sécurité
                            </span>
                        </li>
                        <li className="w-fit mx-auto flex items-start gap-2">
                            <i className="text-xl 2xl:text-3xl text-secondary fa-solid fa-square-check" />
                            <span className="">
                                Installer des mesures de protection adaptées
                            </span>
                        </li>
                    </ul>
            </>
        ),
        skillsAndOutcome : {
            skills : {
                datas : [
                    "Assurer la sécurité des réseaux informatiques",
                    "Déployer et administrer des infrastructures haut débit des opérateurs",
                    "superviser des serveurs et réseaux, et détecter toute attaque ou intrusion",
                    "Sécuriser, authentifier et filtrer les systèmes"
                ]
            },
            outcome : {
                excerpt :"Au terme de sa formation, le diplômé pourra exercer comme entrepreneur autonome et manager ou au sein des entreprises, des sociétés, des administrations publiques ou privées.",
                datas : [
                    "Administrateur de réseaux",
                    "Assistant ingénieur réseaux",
                    "Responsable d’exploitation en charge de la sécurité et de la qualité réseau",
                    "Assistant technique en réseau et sécurité"
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de licence professionnelle en informatique et management option <span className="text-primary italic font--semibold">Administration et Sécurité des Réseaux d’entreprise</span> est 
                conçu, principalement, pour les étudiants titulaires d’un baccalauréat toutes séries confondues. Toutefois, l’admission directe en L3 Administration et sécurité des réseaux d’entreprise 
                est conditionnée par l’obtention d’un diplôme Bac+2 en Informatique ou par la validation d’un parcours de 120 crédits en Mathématiques ou dans les domaines équivalents.

            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel 
                qui représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            breadcrumb : "/images/ifim-gabon-pexels-rodnae-productions-9034249.jpg",
            skillAndOutcome : "/images/ifim-gabon-group-afro-americans-working-together.jpg",
            conditions : "/images/ifim-gabon-tech-2.png"
            // conditions : "/images/ifim-gabon-content-people-posing-stairs.jpg"
            // ifim-gabon-tech-2.png
        }
    },
    {
        slug : "developpement-web-et-apllication-mobile",
        category : "Informatique",
        name : "Développement Web et Application Mobile",
        objectif : (
            <>
                La Licence Professionnelle en Informatique et Management option <span className="text-primary italic font-semibold">Développement Web et Application Mobile</span> confère des 
                connaissances pratiques et techniques dans la conception et la production d’application web et mobile.
            </>
        ),
        skillsAndOutcome : {
            skills : {
                datas : [
                    "Concevoir, développer et administrer des bases de données",
                    "Concevoir et développer des applications de bases de données",
                    "Extraire des données d’une base de données et les effectuer leur migration."
                ]
            },
            outcome : {
                excerpt : "Au terme de sa formation, le diplômé pourra exercer comme entrepreneur autonome ou au sein des entreprises en tant que:",
                datas : [
                    "Développeur Web front-end ou back-end",
                    "Développeur d’application mobile",
                    "Webdesigner"
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de licence professionnelle en informatique et management option <span className="text-primary italic font--semibold">Développement Web et Application Mobile</span> 
                est conçu, principalement, pour les étudiants titulaires d’un baccalauréat toutes séries confondues. Toutefois, l’admission directe en L3 Développement Web et Application Mobile 
                est conditionnée par l’obtention d’un diplôme Bac+2 en Informatique ou par la validation d’un parcours de 120 crédits en Mathématiques ou dans les domaines équivalents.

            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel 
                qui représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            breadcrumb : "/images/ifim-gabon-high-angle-keyboard.jpg",
            skillAndOutcome : "/images/ifim-gabon-tech.png",
            conditions : "/images/ifim-gabon-happy-students.jpg"
        }
    },
    {
        slug : "logistique-et-systeme-d-information",
        category : "Management",
        name : "Logistique et Système d’Information",
        objectif : (
            <>
                Cette formation a pour objectif de développer les compétences permettant de mettre l’informatique au service de la chaîne logistique, notamment la gestion des flux physiques, 
                financiers et informationnels sur toute la chaîne de production (transport national et international, approvisionnement, production, stockage, livraison, retour de marchandise, etc.).
            </>
        ),
        skillsAndOutcome : {
            skills : {
                excerpt : "Le diplômé sera capable de :",
                datas : [
                    "superviser les activités du site logistique dans le respect des dispositions réglementaires",
                    "gérer toute la chaîne des opérations logistiques (stocks, commandes, distribution) dans un souci constant d’optimisation économique",
                    "gérer les méthodes de gestion des approvisionnements et des stocks et optimiser les flux",
                    "piloter la performance de la chaîne logistique",
                    "identifier les besoins et conduire la réalisation des projets logistiques",
                    "assister le schéma directeur logistique",
                    "gérer la demande & les recherches des ventes​",
                    "gérer les systèmes logistiques (TPS, Lean Management et VSM, 6 Sigma, …)"
                ]
            },
            outcome : {
                datas : [
                    "responsable des systèmes d’informations logistiques",
                    "assistant logistique",
                    "responsable des expéditions",
                    "responsable des approvisionnements",
                    "responsable des méthodes logistiques"
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de licence professionnelle en informatique et management option <span className="text-primary italic font--semibold">Logistique et Système d’Information</span> 
                est conçu, principalement, pour les étudiants titulaires d’un baccalauréat toutes séries confondues. Toutefois, l’admission directe en L3 Logistique et systèmes d’information 
                est conditionnée par l’obtention d’un diplôme Bac+2 ou la validation d’un parcours de 120 crédits en Logistique ou en Informatique.

            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel 
                qui représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            breadcrumb : "/images/ifim-gabon-pexels-yan-krukov-8199595.jpg",
            skillAndOutcome : "/images/ifim-gabon-pexels-rfstudio-3810788.jpg",
            conditions : "/images/ifim-gabon-pexels-mikhail-nilov-9158718.jpg"
        }
    },
    {
        slug : "marketing-digital-et-e-commerce",
        category : "Management",
        name : "Marketing Digital et E-Commerce",
        objectif : (
            <>
                Développer des compétences permettant d’adapter la pratique du marketing à l’usage de l’outil électronique.
            </>
        ),
        skillsAndOutcome : {
            skills : {
                excerpt : "Le diplômé sera capable de :",
                datas : [
                    "créer et manager un site web",
                    "caractériser le marketing Digital (Définition, intérêt, spécificités)",
                    "élaborer un plan marketing digital (5p : projet ; plateforme ; partenariat ; promotion)",
                    "vendre en ligne",
                    "évaluer les performances des actions marketing digitales à l’aide des indicateurs spécifiques",
                    "gérer les différentes stratégies sur le web",
                    "gérer la demande & les recherches des ventes",
                    "piloter son référencement naturel et payant",
                    "mener des campagnes payantes sur les réseaux sociaux",
                    "mener des campagnes payantes sur Google"
                ]
            },
            outcome : {
                datas : [
                    "Assistant projet web marketing",
                    "Chargé du web marketing",
                    "Chargé de la communication digitale",
                    "Assistant E-commerce",
                    "Assistant content manager"
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de licence professionnelle en informatique et management option <span className="text-primary italic font--semibold">Marketing Digital et E-Commerce</span> est conçu, 
                principalement, pour les étudiants titulaires d’un baccalauréat toutes séries confondues. Toutefois, l’admission directe en L3 Marketing Digital et E-Commerce est conditionnée par 
                l’obtention d’un diplôme Bac +2 ou la validation d’un parcours de formation de 120 crédits en Gestion et en Informatique.

            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel 
                qui représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            breadcrumb : "/images/ifim-gabon-medium-shot-smiley-woman-presenting.jpg",
            skillAndOutcome : "/images/ifm-gabon-african-females-business-meeting.jpg",
            conditions : "/images/ifim-gabon-pexels-charlotte-may-5965717.jpg"
        }
    },
    {
        slug : "gestion-dest-tres-petites-entreprises",
        category : "Management",
        name : "Gestion des Très Petites Entreprises",
        objectif : (
            <>
                Donner à l’étudiant les outils et habiletés nécessaires pour être à mesure de créer et/ou de gérer des petites entreprises existantes avec la plus-value informatique, 
                c’est-à-dire une capacité d’exploiter la compétence informatique dans la création et la gestion de la petite entreprise.
            </>
        ),
        skillsAndOutcome : {
            skills : {
                subDatas :[
                    {
                        label : "Compétences comportementales",                    
                        datas : [
                            "faire preuve de créativité, d’intuition voire de flair",
                            "s’impliquer avec détermination et persévérance dans ses projets",
                            "s’adapter facilement à des situations variées."
                        ]
                    },
                    {
                        label : "Compétences entrepreneuriales",                    
                        datas : [
                            "imaginer les produits ou services correspondant aux besoins insatisfaits actuels et futurs créer de nouveaux produits réalisables techniquement, vendables et rentables",
                            "construire son plan d’affaire en définissant sa stratégie et ses plans d’action",
                            "penser l’organisation, réunir et répartir les moyens, coordonner les actions."
                        ]
                    },
                    {
                        label : "Compétences managériales",                    
                        datas : [
                            "être reconnu comme leader en obtenant l’implication des collaborateurs et des collaboratrices mais aussi partenaires",
                            "communiquer de façon pertinence en interne comme à l’extérieur de l’entreprise",
                            "manager le personnel et l’organisation",
                            "suivre et contrôler la réalisation des objectifs"
                        ]
                    }
                ]
            },
            outcome : {
                excerpt : "Le diplômé est appelé à :",
                datas : [
                    "mettre sur pied une petite entreprise dans différents secteurs de référence, à très fort potentiel de développement (Agriculture-Industrie-Services-Bâtiment-Commerce-NTIC, etc.)",
                    "créer une très petite entreprise ou gérer une très petite entreprise existante ou reprendre une petite entreprise au bord de la faillite ou en mal de croissance/stabilisation pour la remettre sur le rail",
                    "exercer comme conseiller des entrepreneurs en herbe ou déjà établis pour leur faire bénéficier de son expertise et les aider à s’étendre."
                ]
            } 
        },
        adminissionCondition : (
            <>
                Le programme de licence professionnelle en informatique et management option <span className="text-primary italic font--semibold">Gestion des Très Petites Entreprises</span> 
                est conçu, principalement, pour les étudiants titulaires d’un baccalauréat toutes séries confondues. Toutefois, l’admission directe en L3 GTPE est conditionnée par l’obtention 
                d’un diplôme Bac+2 ou par la validation d’un parcours de 120 crédits toutes disciplines confondues.

            </>
        ),
        evaluationMode : (
            <>
                L’évaluation finale est faite en deux phases. La première phase porte sur le contrôle continu qui représente 40 % de la note finale. La deuxième phase est relative à l’examen semestriel 
                qui représente 60 % de la note finale. Une session de rattrapage est prévue pour des étudiants n’ayant pas obtenu les 60 crédits exigés lors de la session normale
            </>
        ),
        formationProgression : (
            <>
                La formation s’étale sur six (6) semestres. Elle comprend des enseignements théoriques et pratiques. Elle est complétée par l’élaboration et soutenance publique d’un projet 
                professionnel/entrepreneurial. De manière optionnelle, l’étudiant peut effectuer un stage professionnel de quatre (4) semaines au terme duquel il rédigera un rapport de stage. La formation est validée avec 180 crédits.
            </>
        ),
        images : {
            breadcrumb : "/images/ifim-gabon-young-millennials-african-friends-outdoor.jpg",
            skillAndOutcome : "/images/ifim-gabon-focused-woman-student-working-computer.jpg",
            conditions : "/images/ifim-gabon-young-african-female-student.jpg"
        }
    },
]

export default FORMATIONS
