import { useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "../components/container";
import Slider from "../components/slider";
import FORMATIONS from "./formation.all-data";

export default function HomePage() {
    
    useEffect( () => {
        document.title = "IFIM Gabon | Accueil"
    }, [])
    
    return (
        <>
            {/* slider section start */}
            <section className="">
                <Slider
                    autoplay={ { delay : 4000, disableOnInteraction : false }}
                    loop
                    grabCursor
                    speed={2000}
                >
                    <div className="relative flex-1 w-full">
                        <img src="/images/ifim-gabon-group-five-african-college-students-spending-time-together-campus-university.jpg" alt="" className="absolute z-10 w-full h-full object-cover" />
                        <div className="relative z-20 w-full min-h-[100vh] bg-[#00000014] flex flex-col items-center justify-center gap-4 ">
                            <h1 className="max-w-3xl leading-normal font-bold text-secondary drop-shadow-md text-center text-3xl sm:text-5xl md:text-6xl xl:text-7xl">INSTITUT FACULTAIRE <span className="text-primary">D'INFORMATIQUE ET</span> DE MANAGEMENT</h1>
                            <h3 className="text-center leading-6 text-2xl sm:text-4xl md:text-5xl  font-semibold text-white">école à vocation entrepreneuriale</h3>
                        </div>
                    </div>
                    <div className="relative flex-1 w-full">
                        <img src="/images/ifim-gabon-content-people-posing-stairs.jpg" alt="" className="absolute z-10 w-full h-full object-cover" />
                        <div className="relative z-20 w-full min-h-[100vh] bg-[#00000014] flex flex-col items-center justify-center gap-4 ">
                            <h4 className="text-2xl sm:text-4xl md:text-5xl text-center text-white font-bold">La triptyque qui nous guide</h4>
                            <div className="flex flex-col md:flex-row items-center md:items-start gap-x-8 gap-y-4 my-5">
                                <span className="text-4xl sm:text-5xl md:text-6xl xl:text-7xl font-bold text-green-600">SCIENCE</span>
                                <span className="text-4xl sm:text-5xl md:text-6xl xl:text-7xl font-bold text-secondary relative">CONSCIENCE</span>
                                <span className="text-4xl sm:text-5xl md:text-6xl xl:text-7xl font-bold text-green-600">AUTONOMIE</span>
                            </div>
                            {/* <h1 className="max-w-2xl leading-[1.3] font-bold text-green-600 drop-shadow-md text-center text-6xl">INSTITUT FACULTAIRE D'INFORMATIQUE ET DE MANAGEMENT</h1> */}
                            <h3 className="text-2xl sm:text-4xl md:text-5xl font-bold text-white">CROIRE EN SOI</h3>
                        </div>
                    </div>
                    <div className="relative flex-1 w-full">
                        <img src="/images/ifim-gabon-group-four-african-girls-posed-outdoor.jpg" alt="" className="absolute z-10 w-full h-full object-cover" />
                        <div className="relative z-20 w-full min-h-[100vh] bg-[#00000014] flex flex-col items-center justify-center gap-4 ">
                            <h3 className="text-2xl sm:text-4xl md:text-5xl text-center font-bold uppercase text-white">école à triple compétence</h3>
                            <ul className="list-none text-center grid gap-y-2">
                                <li className="text-green-600 text-4xl sm:text-5xl md:text-6xl xl:text-7xl font-bold">INFORMATIQUE</li>
                                <li className="text-secondary text-4xl sm:text-5xl md:text-6xl xl:text-7xl font-bold">MANAGEMENT</li>
                                <li className="text-green-600 text-4xl sm:text-5xl md:text-6xl xl:text-7xl font-bold">ENTREPRENEURIAT</li>
                            </ul>
                        </div>
                    </div>
                </Slider>
            </section>
            {/* slider section ends */}

            {/* section context et justification ends */}
            <section className="py-28" >
                <Container className="relative z-20 grid xl:grid-cols-2 min-h-[100vh]">
                    <div className="lg:pr-12 text-justify">
                        <h1 className="mb-2 2xl:mb-12 2xl:max-w-2xl text-4xl 2xl:text-6xl text-left 2xl:text-center font-bold text-primary">CONTEXTE ET JUSTIFICATION DE LA FORMATION</h1>
                        <img src="/images/ifim-gabon-group-five-african-college-students.jpg" alt="" className="block my-4 md:hidden w-full object-cover rounded-md" />
                        <p className="2xl:">
                            Depuis une dizaine d’années, nous nous rendons à l’évidence que le nombre de diplômés, au sortir des différents établissements supérieurs, est de plus en plus élevé chaque année ; ce qui rend les opportunités d’emploi moins accessibles.
                        </p>
                        <p className="my-4 2xl:">
                            De ce fait, il devient opportun de <span className="text-primary font-semibold">« croire en soi »</span> en pensant à des <span className="text-primary font-semibold">formations professionnalisantes de niveau supérieur et à un changement de paradigmes</span> concernant le marché de l’emploi.
                        </p>
                        <p className="my-4">
                            <span className="2xl:">
                                Il revient donc à chaque promoteur d’établissement privé supérieur de s’interroger sur l’objectif réel de son action, afin d’œuvrer collectivement et de renverser la courbe. Nous partons, pour cela, d’un questionnement qui se veut constructif :
                            </span>
                        </p>
                        <ul className="mt-4 list-none grid gap-y-4  text-primary">
                            <li className="flex items-start gap-2">
                                <i className="text-xl 2xl:text-3xl  fa-solid fa-square-check" />
                                <span className="">
                                    de quels modèles de réussite s’inspirent les jeunes diplômés gabonais aujourd’hui ?
                                </span>
                            </li>
                            <li className="flex items-start gap-2">
                                <i className="text-xl 2xl:text-3xl  fa-solid fa-square-check" />
                                <span className="">
                                    Ces modèles, s’ils existent réellement, donnent-t-ils l’assurance d’une génération pouvant relever des défis ?
                                </span>
                            </li>
                            <li className="flex items-start gap-2">
                                <i className="text-xl 2xl:text-3xl  fa-solid fa-square-check" />
                                <span className="">
                                    Aujourd’hui, considérant les formations proposées dans nos différentes institutions d’enseignement supérieur et professionnel, donnons-nous les moyens suffisants, permettant à cette génération de montrer ce dont elle est capable ? d’exprimer son talent ou de se mettre en valeur ?
                                </span>
                            </li>
                        </ul>

                        <p className=" hidden 2xl:block">
                            Une observation du marché de l’emploi nous a permis de prendre conscience et de repenser la formation autrement. À cet effet, l’<span className="font-semibold text-primary">Institut Facultaire d'Informatique et de Management</span> se propose de former en garantissant d’abord un auto-emploi. En effet, les établissements supérieurs privés sont de plus en plus présents aux côtés des établissements supérieurs publics ; L’objectif étant de pallier la carence en infrastructures d’accueil due à la croissance exponentielle des effectifs étudiants, et d’octroyer une adéquation avec le marché de l’emploi qui devient de plus en plus exigent en termes de compétences et de flexibilité.
                        </p>
                    </div>
                    <div className="hidden md:block h-full w-full">
                        <img src="/images/ifim-gabon-group-five-african-college-students.jpg" alt="" className="h-full w-full object-cover rounded-md" />
                    </div>
                </Container>
            </section>
            {/* section context et justification ends */}

            {/* section objectif de la formation ends */}
            <section className="py-28 bg-cover bg-center relative after:absolute  after:inset-0 after:bg-primary after:bg-opacity-50" style={{ backgroundImage : "url(/images/ifim-gabon-pexels-tobias-bg.jpg)" }}>
                <Container className="relative z-10 grid xl:grid-cols-2 min-h-[100vh] text-white">
                    <div className="hidden md:block h-full w-full">
                        <img src="/images/ifim-gabon-content-people-posing-stairs.jpg" alt="" className="h-full w-full object-cover rounded-md" />
                    </div>
                    <div className="lg:pl-12 text-justify ">
                        <h1 className="mb-2 2xl:mb-12 2xl:max-w-2xl text-4xl 2xl:text-6xl text-left 2xl:text-center font-bold text-secondary">OBJECTIF DE LA FORMATION</h1>
                        <img src="/images/ifim-gabon-content-people-posing-stairs.jpg" alt="" className="block my-4 lg:hidden  w-full object-cover rounded-md" />
                        <p className="2xl:">
                            L’<span className="font-semibold text-secondary">Institut Facultaire d'Informatique et de Management</span> propose une formation visant les nouvelles technologies, et à triple compétences : l’informatique, le management et l’entrepreneuriat. Au vu des objectifs de professionnalisation visés par l’établissement, l’<span className="font-semibold text-secondary">Institut Facultaire d'Informatique et de Management</span> doit pouvoir concilier les aspects théoriques et les aspects pratiques.
                        </p>
                        <p className="my-4 2xl:">
                        L’objectif de la formation est de donner des outils nécessaires à l’étudiant pour l’amener à participer, à concevoir, à réaliser ou à élaborer des solutions informatiques et managériales répondant aux besoins des entreprises du 21e siècle et aux exigences de l’entrepreneuriat.
                        </p>
                        <p className="my-4">
                            <span className="font-semibold">
                                Tout étudiant formé à l’<span className="font-semibold text-secondary">Institut Facultaire d'Informatique et de Management</span> doit développer des compétences dans les domaines de l’Informatique et du Management
                            </span> (expertise technologique, capacité d’analyse, maîtrise des concepts et architectures informatiques, psychologie des affaires, qualités relationnelles et compréhension des problématiques « Business » de l’entreprise et la sociologie appliquée). En outre, il doit être capable d’entreprendre dans ces domaines.
                        </p>

                        <p className="">
                        Les jeunes gabonais comme tous ceux de leur génération, sont dotés de talents. Une fois diplômés, ils sont remplis de motivation et ouverts à toute activité qui leur permettrait de vivre décemment. C’est dans ce contexte que vient s’inscrire l’<span className="font-semibold text-secondary">Institut Facultaire d'Informatique et de Management</span>.
                        </p>
                    </div>
                </Container>
            </section>
            {/* section objectif de la formation ends */}

            {/* section public concerné et profil de sorti ends */}
            <section className="py-28">
                <Container className="relative z-20 grid xl:grid-cols-2 items-center min-h-[100vh]">
                    <div className="lg:pr-12 text-justify">
                        <h1 className="mb-2 2xl:mb-12 2xl:max-w-2xl text-4xl 2xl:text-6xl text-left 2xl:text-center font-bold text-primary uppercase">PUBLIC CONCERNé et PROFIL DE SORTIE</h1>
                        <img src="/images/ifim-gabon-young-millennials-african-friends-outdoor.jpg" alt="" className="lg:hidden w-full object-cover rounded-md" />
                        <div className="my-6">
                            <h3 className="uppercase font-semibold text-3xl text-primary xl mb-2 pb-1 border-b border-b-secondary">PUBLIC CONCERNé</h3>
                            <p className="">
                                Le programme de <span className="font-semibold text-primary">Licence Professionnelle en Informatique et Management</span> est conçu, principalement, pour les étudiants titulaires d’un baccalauréat toutes séries confondues.
                            </p>
                        </div>
                        <div className="my-6">
                            <h3 className="uppercase font-semibold text-3xl text-primary xl mb-2 pb-1 border-b border-b-secondary">PROFIL DE SORTIE</h3>
                            <p className="">
                            La <span className="font-semibold text-primary">Licence Professionnelle en Informatique et Management</span> prépare à l’ensemble des métiers liés à l’informatique et à leur application au management que ce soit à des fins de création d’entreprises, que d’intégration d’une organisation existante dans tous les secteurs d’activités.
                            </p>
                        </div>
                    </div>
                    <div className="hidden md:block h-full w-full">
                        <img src="/images/ifim-gabon-young-millennials-african-friends-outdoor.jpg" alt="" className="h-full w-full object-cover rounded-md" />
                    </div>
                </Container>
            </section>
            {/* section public concerné et profil de sorti ends */}

            {/* section competences starts*/}
            <section className="py-28 bg-cover bg-center relative after:absolute after:inset-0 after:bg-primary after:bg-opacity-50" style={{ backgroundImage : "url(/images/ifim-gabon-bg.jpg)" }}>
                <Container className="relative z-10 grid md:grid-cols-2 h-fit text-white">
                    <div className="hidden md:block">
                        <img src="/images/ifim-gabon-group-afro-americans-working-together.jpg" alt="" className="w-full h-full object-cover rounded-md" />
                    </div>
                    <div className="md:pl-10">
                        <h1 className="text-4xl 2xl:text-6xl text-left font-bold text-secondary uppercase">COMPéTENCES VISéES</h1>
                        <img src="/images/ifim-gabon-group-afro-americans-working-together.jpg" alt="" className="my-4 md:hidden w-full object-cover rounded-md" />
                        <p className=" my-10">
                            L’esprit d’entreprise est la première compétence visée par l’école. Tout étudiant formé à l’IFIM doit : 
                            être à l’affût des opportunités et prendre des risques réfléchis ; rechercher des idées nouvelles dans 
                            le souci d’amélioration continue ; adopter une stratégie pro-active face à l’environnement, être un agent 
                            de changement relevant des défis d’envergure.
                        </p>
                        <div className="">
                            <div className="flex flex-col gap-y-5">
                                <h3 className="text-3xl text-secondary pb-2 border-b border-secondary w-fit">En termes de savoir</h3>
                                <ul className="list-noe flex flex-col gap-y-2">
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            maîtriser les méthodes d’analyse, de conception de solutions applicatives des systèmes d’information, des outils de génie logiciel, du modèle relationnel et des langages formels ;
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            mettre en œuvre et utiliser des bases de données ;
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            maîtriser des techniques de développement des services Web, mobile, architecture réseaux ;
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            maîtriser des outils de création d’entreprise ;
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            maîtriser les techniques d’élaboration des comptes financiers ;
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            maîtriser les techniques de vente.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-y-5 my-4">
                                <h3 className="text-3xl text-secondary pb-2 border-b border-secondary w-fit">En termes de savoir-faire</h3>
                                <ul className="list-noe flex flex-col gap-y-2">
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            développer et mettre en œuvre des solutions informatiques web et mobiles : savoir administrer un réseau d’entreprises, assurer la sécurisation des systèmes, savoir administrer une base de données d’entreprise ;
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            développer et mettre en œuvre un dispositif marketing des produits et services informatique effectuer les pratiques de gestion (comptabilité, gestion des ressources humaines, marketing, logistiques) au moyen des outils informatiques et digitaux
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            rédiger un projet professionnel/entrepreneurial
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            rédiger un rapport de stage
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            créer une entreprise et la diriger.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-y-5">
                                <h3 className="text-3xl text-secondary pb-2 border-b border-secondary w-fit">En termes de savoir</h3>
                                <ul className="list-noe flex flex-col gap-y-2">
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            faire preuve d’ouverture, de respect et responsabilité dans les relations professionnelles
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            intégrer l’esprit d’entreprise dans l’analyse des problèmes au sein des organisations et proposer des solutions efficaces et éthiques
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            intégrer une équipe dynamique de travail dans le domaine de l’informatique
                                        </span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary group-hover:text-secondary fa-solid fa-square-check" />
                                        <span className="group-hover:text-secondary group-hover:text-xl">
                                            exercer des fonctions d’encadrement dans une organisation.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            {/* section competences ends */}

            {/* section formations ends */}
            <section className="py-28">
                <Container className="relative z-20 grid md:grid-cols-2 items-center min-h-[100vh]">
                    <div className="lg:pl-12 text-justify ">
                        <h1 className="mb-2 2xl:mb-12 2xl:max-w-2xl text-4xl 2xl:text-6xl text-left 2xl:text-center font-bold text-primary">NOS FORMATIONS</h1>
                        <img src="/images/ifim-gabon-pexels-monstera-6238038.jpg" alt="" className="lg:hidden w-full object-cover rounded-md" />
                        
                        <p className=" hidden 2xl:block">
                            
                        </p>

                        <ul className="mt-4 list-none divide-y-8 divide-transparent">
                            {
                                FORMATIONS.map( ({name, slug}) => (
                                    <li key={`formation-${slug}`} className="">
                                        <Link to={`/formations/${slug}`} className="group flex items-start gap-2">
                                            <i className="text-xl 2xl:text-3xl text-primary group-hover:text-secondary fa-solid fa-square-check" />
                                            <span className="group-hover:text-primary group-hover:text-xl">{name}</span>
                                        </Link>
                                    </li>
                                )) 
                            }
                        </ul>
                    </div>
                    <div className="hidden md:block h-full w-full">
                        <img src="/images/ifim-gabon-pexels-monstera-6238038.jpg" alt="" className="h-full w-full object-cover rounded-md" />
                    </div>
                </Container>
            </section>
            {/* section formations ends */}
        </>
    )
};
