import { useEffect } from "react";
import Breadcrumb from "../components/breadcrumb";
import Container from '../components/container';
import MyForm, { InputFieldCustomSelect, InputFieldTextArea } from "../components/my-form";

export default function ContactPage() {

    useEffect( () => {
        document.title = "IFIM Gabon | Contact"
    }, [])

    return (
        <>
            <Breadcrumb pageTitle="Contact" bgImage="/images/ifim-gabon-contact.jpg" bgImagePosition="center" />
            <section className="pt-28 pb-20 flex flex-col gap-y-8">
                <Container className="flex flex-col gap-y-14">
                    <h1 className="text-4xl md:text-primary md:text-7xl font-semibold text-center">Contactez-nous</h1>

                    <div className="flex flex-wrap items-center justify-center gap-4">
                        <div className="w-full md:w-80 flex flex-col items-center gap-y-4 border border-primary rounded-md p-4">
                            <i className="flex justify-center items-center bg-primary text-white w-14 h-14 rounded-full fas fa-map text-3xl" />
                            <h4 className="text-primary font-semibold text-4xl text-center">Adresse</h4>
                            <span className="text-primary text-center font-semibold ">Rue Juan Carlos N°196 Waterman, 16244 Libreville, Gabon</span>
                        </div>
                        <div className="w-full md:w-80 flex flex-col items-center gap-y-4 border border-primary rounded-md p-4">
                            <i className="flex justify-center items-center bg-primary text-white w-14 h-14 rounded-full fas fa-phone text-3xl" />
                            <h4 className="text-primary font-semibold text-4xl text-center">Téléphone</h4>
                            <ul className="list-none text-center text-primary font-semibold">
                                <li>(+241) 066-255-311</li>
                                <li>(+241) 074-509-157</li>
                            </ul>
                        </div>
                        <div className="w-full md:w-80 flex flex-col items-center gap-y-4 border border-primary rounded-md p-4">
                            <i className="flex justify-center items-center bg-primary text-white w-14 h-14 rounded-full fas fa-envelope text-3xl" />
                            <h4 className="text-primary font-semibold text-4xl text-center">Mail</h4>
                            <ul className="list-none text-center text-primary font-semibold">
                                <li>infos@ifimgabon.com</li>
                                <li>inscription@ifimgabon.com</li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </section>
            
            
        </>
    )
};