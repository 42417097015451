import { useContext, useEffect, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { LayoutContext } from '../contexts/layout.context';
import Logo from './logo';

export default function Footer(){
    
    const {pathname} = useLocation()
    const [showGoToTopButton, setShowGoToTopButton] = useState(true)
    const {isFixed, menuItems} = useContext(LayoutContext)

    useEffect( () => {
        const onScroll = () => setShowGoToTopButton( window.scrollY > 100)
        window.addEventListener("scroll", onScroll)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    return (
        
        <footer className="w-full bg-primary text-white">
            <button className={`fa-solid fa-arrow-right-from-bracket fixed z-30 bottom-14 right-10 text-xl -rotate-90 ${showGoToTopButton ? "flex" : "hidden"} justify-center items-center bg-secondary hover:bg-black text-white w-10 h-10 rounded-md`} onClick={ () => window.scrollTo(0,0)} />
            <div className="container mx-auto px-3">
                <nav className="border-b border-b-secondary border-opacity-60 py-6 sm:py-12 px-6 md:px-12 grid md:justify-center gap-y-6 md:grid-cols-4">
                    <div className="w-fit">
                        <Logo />
                        <p className="md:pr-4">
                            Rue Juan Carlos N°196 Waterman, 16244 Libreville, Gabon
                        </p>
                    </div>
                    <div className="w-fit">
                        <h3 className="text-secondary font-semibold text-2xl">Liens</h3>
                        <div className="mt-6 flex flex-col gap-2">
                            { menuItems.map( (item, index) => (
                                <NavLink key={`footer-page-${index}`} className={ ({isActive}) => `w-fit ml-1 ${isActive ? "font-semibold" : "" }`} to={item.link}>
                                    {/* { pathname.includes( item.link ) && <i className="mr-2 fa-solid fa-arrow-right-from-bracket" /> } */}
                                    {item.label}
                                </NavLink>
                            )) }
                        </div>
                    </div>
                    <div className="w-fit">
                        <h3 className="text-secondary font-semibold text-2xl">Contact</h3>
                        <div className="mt-6 flex flex-col gap-2">
                            <a href="tel:(+241) 066-255-311" className="">
                                <i className="fas fa-phone mr-2" />(+241) 066-255-311
                            </a>
                            <a href="tel:(+241) 074-509-157" className="">
                                <i className="fas fa-phone mr-2" />(+241) 074-509-157
                            </a>
                            <a href="mailto:infos@ifimgabon.com" className="">
                                <i className="fas fa-envelope mr-2" />infos@ifimgabon.com
                            </a>
                            <a href="mailto:inscription@ifimgabon.com" className="">
                                <i className="fas fa-envelope mr-2" />inscription@ifimgabon.com
                            </a>
                        </div>
                    </div>
                    <div className="w-fit">
                        <h3 className="text-secondary font-semibold text-2xl">Réseaux</h3>
                        <div className="mt-6 flex flex-col gap-2">
                            {/* <Link target="_blank" className="w-fit hover:text-primary" to="" >Linkedin</Link>
                            <Link target="_blank" className="w-fit hover:text-primary" to="" >Twitter</Link> */}
                            <a target="_blank" className="w-fit" href="https://www.facebook.com/profile.php?id=100071547506307" rel="noreferrer" > <i className="fab fa-facebook" /> Facebook</a>
                            {/* <Link target="_blank" className="w-fit hover:text-primary" to="" >Instagram</Link> */}
                        </div>
                    </div>
                </nav>
                <div className="py-6 flex flex-col md:flex-row items-center justify-between">
                    <div className="">
                        Copyright <i className="fa-regular fa-copyright" /> 2022
                    </div>
                    <div className="">
                        Réalisé par <a target="_blank" href="https:www.icashbs.com" className="text-secondary font-semibold italic" rel="noreferrer">ICASH Business Solutions</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}