import { useContext, useEffect, useState, useMemo } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import Container from "./container";
import Logo from "./logo";
import { LayoutContext } from '../contexts/layout.context';
import FORMATIONS from '../pages/formation.all-data';

type menuItemProps = {
    label : string
    link : string
    subMenu? : {label : string, link : string, isMain? : boolean}[]
}
function MenuItem({ label, link, subMenu } : menuItemProps){

    const location = useLocation()
    const [showSubMenu, setShowSubMenu] = useState<boolean>()

    useEffect( () => {
        document.onclick = () => setShowSubMenu( false )
    }, [])

    useEffect( () => {
        setShowSubMenu( false )
    }, [location])
    
    return !subMenu ? (
        <NavLink to={link} className={ ({isActive}) => `${isActive ? "font-bold bg-secondary" : ""} text-white py-4 lg:py-1 px-4 lg:rounded-md border-b lg:border-none border-b-secondary `} >
            {label}
        </NavLink>
    ) : (
        <div className="relative">
            <NavLink 
                to={link} 
                onClick={ e => { e.preventDefault(); e.stopPropagation(); setShowSubMenu( prev => !prev)}} 
                className={ ({isActive}) => `${isActive ? "font-bold bg-secondary" : "border-b lg:border-b-0"} ${showSubMenu ? "bg-primary lg:border-b rounded-tl-md rounded-tr-md" : ""} lg:max-w-[10rem] flex justify-between text-white py-4 lg:py-1 px-4  border-b-secondary `} >
                {label} <i className={`ml-2 fa-solid fa-arrow-up-from-bracket ${showSubMenu ? "rotate-180":"rotate-90"}`} />
            </NavLink>
            <div className={`w-full lg:w-60 lg:absolute left-0 z-20 bg-primary divide-y divide-gray-300 rounded-bl rounded-br shadow border-l-4 border-l-secondary lg:border-none ${showSubMenu ? 'block' : 'hidden'}`}>
                <nav className="w-full py-3 px-2 grid gap-y-3 text-sm text-white" aria-labelledby="liste des formations disponible">
                    { subMenu?.map( (subMenuItem, index) => (
                        <NavLink to={`${link}/${subMenuItem.link}`} key={index} className={ ({isActive}) => `${isActive ? "font-bold bg-secondary" : ""} ${subMenuItem.isMain ? "border-b-2 border-b-secondary" : "lg:rounded"} w-full py-4 lg:py-1 px-4 border-b-secondary `} >
                            {subMenuItem.label}
                        </NavLink>
                    ))}
                    {/* // <MenuItem label={subMenuItem.label} link={`${link}/${subMenuItem.link}`} key={index} /> )) } */}
                </nav>
            </div>
        </div>
    )
}
export default function Header(){
    const location = useLocation()
    const {isFixed, menuItems} = useContext(LayoutContext)
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    useEffect( () => {
        setShowMobileMenu( false )
    }, [location])
    useEffect(() =>{
        const onScroll = () => setShowMobileMenu(false)
        // window.addEventListener("scroll", onScroll)

        return () => window.removeEventListener("scroll", onScroll)

    }, [])

    return (
        <header className={ ` ${isFixed ? " bg-opacity-100" : " bg-opacity-50"} fixed top-0 z-50 w-full py-4 bg-primary`}>
            <Container className="flex justify-between items-center">
                <Logo className="" />

                <nav className="hidden lg:flex gap-2">
                    { menuItems.map( (menuItem, index) => <MenuItem key={`menu-item-${index}`} {...menuItem} />) }
                </nav>
                {/* mobile menu */}
                <div className="flex lg:hidden items-center gap-4">
                    <button className={`fas ${showMobileMenu ? "fa-close rotate-180 text-secondary" : "fa-bars"} relative z-50 text-6xl text-white flex justify-center items-center rounded`} onClick={() => setShowMobileMenu(prev => !prev)} onBlur={ () => setShowMobileMenu(false)} />
                    <div className={`fixed z-10 min-h-min top-0 ${showMobileMenu ? "left-0" : "-left-full"} py-2 px-4 w-full bg-primary  h-screen flex sm:hidden flex-col gap-10 `}>
                        <Logo />
                        <nav className="flex-1 overflow-hidden -mx-1 text-sm text-black flex flex-col">
                            { menuItems.map( (menuItem, index) => <MenuItem key={`menu-item-${index}`} {...menuItem} subMenu={ menuItem.link === 'formations' ? undefined : menuItem.subMenu} link={ menuItem.link === 'formations' ? 'formations#toutes-formations' : menuItem.link } />) }
                        </nav>
                    </div>
                </div>
                {/* mobile menu end */}

                <NavLink to="inscription" className={ ({isActive}) => `hidden uppercase font-semibold bg-secondary drop-shadow-sm py-2 px-8 rounded-3xl`}>
                    Inscription
                </NavLink>
            </Container>
            
        </header>
    )
}