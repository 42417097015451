import { createContext, ReactNode, useEffect, useMemo, useState } from "react"
import FORMATIONS from "../pages/formation.all-data"

export type LayoutContextProps = {
    isFixed? : boolean
    menuItems : any[]
}
export const LayoutContext = createContext<LayoutContextProps>({ menuItems : [] })

export default function LayoutContextProvider ( {children} : {children : ReactNode}) {
    const [isFixed, setIsFixed] = useState(false)
    const menuItems = useMemo( () => [
        { label : "Accueil", link : "/"},
        { label : "Mot du Fondateur", link : "mot-du-fondateur"},
        {
            label : "Formations", 
            link : "formations",
            subMenu : [ {label : "Oraginisation des formations", link : "", isMain : true}, ...FORMATIONS.map( formation => ({ label : formation.name, link : formation.slug}))]
        },
        { label : "Contact", link : "contact"},
    ], [])
    
    useEffect( () => {
        const onscroll = (e : any) => setIsFixed( window.scrollY > 60 )
        window.addEventListener("scroll", onscroll) 
        return () => window.removeEventListener("scroll", onscroll)
    }, [])

    return(
        <LayoutContext.Provider value={{ isFixed, menuItems }}>
            {children}
        </LayoutContext.Provider>
    )
}