import { useCallback } from "react"
import Breadcrumb from "../components/breadcrumb"
import Container from "../components/container"

type props = {
    formation : any // {category : string, name : string, objectif? : string, skillsAndOutcome : any } //{ excerpt? : string, datas : string[] }}
}

export default function FormationDetailsPage({formation} : props) {
    
    const RenderSkills = useCallback( () => {
        if(!formation.skillsAndOutcome || !formation.skillsAndOutcome.skills){
            return <></>
        }

        return (
            <div className="">
                <h3 className="font-semibold text-3xl text-secondary">Compétences</h3>
                <p className="">{formation.skillsAndOutcome.skills.excerpt}</p>
                {  formation.skillsAndOutcome.skills.datas && (
                    <ul className="mt-4 list-none divide-y-8 divide-transparent text-sm">
                        { formation.skillsAndOutcome.skills.datas.map( (data : any) => (
                            <li className="flex items-start gap-2">
                                <i className="text-xl 2xl:text-3xl text-secondary fa-solid fa-square-check" />
                                <span className="">
                                    {data}
                                </span>
                            </li>
                        )) }
                    </ul>
                )}

                { formation.skillsAndOutcome.skills.subDatas && (
                    formation.skillsAndOutcome.skills.subDatas.map( ({label, datas} : any) => (
                        <div className="mt-3">
                            <h4 className="text-secondary italic">{label}</h4>
                            <ul className="mt-1 list-none divide-y-8 divide-transparent text-sm">
                                { datas.map( (data : any) => (
                                    <li className="flex items-start gap-2">
                                        <i className="text-xl 2xl:text-3xl text-secondary fa-solid fa-square-check" />
                                        <span className="">
                                            {data}
                                        </span>
                                    </li>
                                )) }
                            </ul>
                        </div>
                    ))
                ) }
            </div>
        )
    }, [formation.skillsAndOutcome])

    const RenderOutcome = useCallback( () => {
        if(!formation.skillsAndOutcome || !formation.skillsAndOutcome.outcome){
            return <></>
        }

        return (
            <div className="mt-4">
                <h3 className="font-semibold text-3xl text-secondary">Débouchés</h3>
                <p className="">{formation.skillsAndOutcome.outcome.excerpt}</p>
                <ul className="mt-4 list-none divide-y-8 divide-transparent text-sm">
                    { formation.skillsAndOutcome.outcome.datas.map( (data : any) => (
                        <li className="flex items-start gap-2">
                            <i className="text-xl 2xl:text-3xl text-secondary fa-solid fa-square-check" />
                            <span className="">
                                {data}
                            </span>
                        </li>
                    )) }
                </ul>
            </div>
        )
    }, [formation.skillsAndOutcome])

    return (
        <>
            <Breadcrumb pageTitle={formation.name} subTitle={`Formation Licence ${formation.category}`} parentPage={ {title : "Nos Formations", link : "/formations"}} bgImage={formation.images?.breadcrumb} bgImagePosition={formation.images?.breadcrumbPosition} />
            <section className="py-28">
                <Container className="flex flex-col gap-y-8">
                    <h1 className="text-4xl md:text-7xl text-primary font-semibold text-center">Objectifs de la formation</h1>
                    <p className="max-w-2xl mx-auto text-center">{formation.objectif}</p>
                </Container>
            </section>
            {/* section skills and outcomes ends */}
            <section className="py-28 bg-cover bg-bottom relative after:absolute after:z-10 after:inset-0 after:bg-primary after:bg-opacity-50" style={{ backgroundImage : "url(/images/ifim-gabon-pexels-matheus-bertelli-4558717.jpg)" }}>
                <Container className="relative z-20 grid xl:grid-cols-2 items-center min-h-[100vh]">
                    <div className="relative  h-full w-full mb-6">
                        <img src={formation.images?.skillAndOutcome || "/images/ifim-gabon-study-group-african-people.jpg"} alt="" className="h-full w-full object-cover rounded-md" />
                        <h1 className="w-full absolute top-1/2 -translate-y-1/2 text-center text-4xl lg:text-5xl 2xl:text-7xl font-bold text-secondary uppercase">Compétences <br/>&<br/> Métiers</h1>
                        
                    </div>
                    <div className="lg:pl-12 flex-1 flex flex-col justofy-between gap-y-4 text-justify  text-white">
                        <RenderSkills />
                        <RenderOutcome />

                        
                    </div>
                </Container>
            </section>
            {/* section skills and outcomes ends */}

            {/* section conditions and so-on */}
            <section className="py-28">
                <Container className="relative z-20 grid xl:grid-cols-2 items-center min-h-[100vh]">
                    <div className="lg:pr-12 flex-1 flex flex-col justofy-between gap-y-4 text-justify">
                        <div className="">
                            <h3 className="font-semibold uppercase text-primary text-left text-2xl mb-2 pb-2 border-b border-b-primary">Conditions d'admission</h3>
                            <p className="">{formation.adminissionCondition}</p>
                        </div>
                        <div className="">
                            <h3 className="font-semibold uppercase text-primary text-left text-2xl mb-2 pb-2 border-b border-b-primary">Mode d'évaluation</h3>
                            <p className="">{formation.evaluationMode}</p>
                        </div>
                        <div className="">
                            <h3 className="font-semibold uppercase text-primary text-left text-2xl mb-2 pb-2 border-b border-b-primary">Déroulement de la formation</h3>
                            <p className="">{formation.formationProgression}</p>
                        </div>

                        
                    </div>
                    <div className="hidden lg:block relative  h-full w-full mb-6">
                        <img src={formation.images?.conditions || "/images/ifim-gabon-study-group-african-people.jpg"} alt="" className="h-full w-full object-cover rounded-md" />
                        {/* <h1 className="w-full absolute top-1/2 -translate-y-1/2 text-center text-4xl lg:text-5xl 2xl:text-7xl font-bold text-secondary uppercase">Compétences <br/>&<br/> Metiers</h1> */}
                        
                    </div>
                </Container>
            </section>
            {/* section objectif de la formation ends */}
        </>
    )
};
