import { Link } from "react-router-dom"

type props = {
    className? : string
}
export default function Logo({className} : props) {
    
    return (
        <div className="">
            <Link to="/" className="flex items-center gap-2 text-secondary text-2xl font-bold">
                <img src="/images/ifim-gabon-logo.png" className="w-14" alt="" />
                IFIM Gabon
            </Link>
        </div>
    )
};
