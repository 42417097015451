import { useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom"
import Breadcrumb from "../components/breadcrumb"
import Container from "../components/container"
import FORMATIONS from "./formation.all-data";
import FormationDetailsPage from "./formation.details.page";
import PageNotFound from "./page-not-found";

export default function FormationMainPage() {
    const pathname = useLocation().pathname.toLowerCase()
    const formationSelected = useMemo( () => {
        if((pathname === "/formations") || (pathname === "/formations/")){
            return undefined
        }else{
            
            const parts = pathname.split('/').filter( part => part !== "")
            parts.shift()
            if(!parts.length || (parts.length > 1)){
                return undefined
            }
            return FORMATIONS.find( ({slug}) => slug === parts[0]) 
        }
    }, [pathname])

    useEffect( () => {
        document.title = `IFIM Gabon | Formations ${formationSelected ? `| ${formationSelected.name}` : ""}`
    }, [formationSelected])

    return ((pathname === "/formations") || (pathname === "/formations/")) ? (

        <>
            <Breadcrumb pageTitle="Nos Formations" description="" bgImagePosition="center" bgImage="/images/ifim-gabon-study-group-african-people.jpg" />
            {/* section objectif de la formation ends */}
            <section className="py-28 bg-cover bg-bottom relative after:absolute after:z-10 after:inset-0 after:bg-primary after:bg-opacity-50" style={{ backgroundImage : "url(/images/ifim-gabon-pexels-matheus-bertelli-4558717.jpg)" }}>
                <Container className="relative z-20 grid xl:grid-cols-2 items-center min-h-[100vh]">
                    <div className="hidden md:block h-full w-full">
                        <img src="/images/ifim-gabon-structure-formation-organigrame.png" alt="" className="h-full w-full object-contain rounded-md" />
                        {/* <span className="text-primary italic text-sm">organigram</span> */}
                    </div>
                    <div className="lg:pl-12 flex flex-col gap-y-4 text-justify  text-white">
                        <h1 className="text-2xl  md:text-4xl font-bold text-secondary">STRUCTURE DE LA FORMATION</h1>
                        <img src="/images/ifim-gabon-structure-formation-organigrame.png" alt="" className="lg:hidden h-72 w-full object-cover object-center rounded-md" />
                        <p className="">
                            La licence Professionnelle en Informatique et Management comprend deux mentions : la mention Informatique et la mention Management. Chaque mention comprend deux parcours, à savoir : le parcours entrepreneurial (informatique/entrepreneur) et le parcours classique (informatique/management).
                        </p>
                        <p className="">
                            La formation est <span className="text-secondary italic">structurée autour d’enseignements, organisés en Cours Magistraux (CM), Travaux Dirigés (TD) ou Travaux Pratiques (TP), Travaux Personnels de l’Étudiant (TPE), de pratiques professionnelles (stages, séminaires, visites d’entreprises, de travaux d’intérêt communautaire, …) et de production d’un travail personnel sous la forme d’un rapport de stage ou d’un projet (professionnel ou entrepreneurial).</span> Toutes ces activités forment des ensembles cohérents d’enseignements qui contribuent à l’acquisition de savoirs, au développement de savoir-faire et à l’acquisition d’un savoir-être indispensable aux métiers auxquels prépare la formation.
                        </p>
                    </div>
                </Container>
            </section>
            {/* section objectif de la formation ends */}

            
            {/* section objectif de la formation ends */}
            <section className="py-28">
                <Container className="grid xl:grid-cols-2 items-center min-h-[100vh]">
                    <div className="hidden md:block h-full w-full">
                        <img src="/images/ifim-gabon-instructor-helping-students.jpg" alt="" className="h-full w-full object-cover rounded-md" />
                    </div>
                    <div className="lg:pl-12 text-justify ">
                        <h1 className="mb-2 2xl:mb-12 2xl:max-w-2xl text-4xl 2xl:text-6xl text-left 2xl:text-center font-bold text-primary uppercase">APPROCHE PéDAGOGIQUE</h1>
                        <img src="/images/ifim-gabon-instructor-helping-students.jpg" alt="" className="block my-4 lg:hidden  w-full object-cover rounded-md" />
                        <p className="">
                            L’<span className="font-semibold text-primary">Institut Facultaire d'Informatique et de Management</span> propose une formation visant les nouvelles technologies, et à triple compétences : l’informatique, le management et l’entrepreneuriat. Au vu des objectifs de professionnalisation visés par l’établissement, l’<span className="font-semibold text-primary">Institut Facultaire d'Informatique et de Management</span> doit pouvoir concilier les aspects théoriques et les aspects pratiques.
                        </p>
                        <p className="my-4 ">
                            Le programme est fondé sur la pédagogie active, une approche basée sur les compétences où les étudiants apprennent au travers de situations concrètes et des projets appliqués à la réalité (« learning by doing ») avec un large recours :
                        </p>
                            <ul className="mt-4 list-none divide-y-8 divide-transparent">
                                <li className="flex items-start gap-2">
                                    <i className="text-xl 2xl:text-3xl text-primary fa-solid fa-square-check" />
                                    <span className="">
                                        aux séminaires, conférences;
                                    </span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <i className="text-xl 2xl:text-3xl text-primary fa-solid fa-square-check" />
                                    <span className="">
                                        à la méthode des cas;
                                    </span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <i className="text-xl 2xl:text-3xl text-primary fa-solid fa-square-check" />
                                    <span className="">
                                        aux travaux individuels et de groupe;
                                    </span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <i className="text-xl 2xl:text-3xl text-primary fa-solid fa-square-check" />
                                    <span className="">
                                        aux exposés.
                                    </span>
                                </li>
                            </ul>
                       
                    </div>
                </Container>
            </section>
            {/* section objectif de la formation ends */}

            {/* section objectif de la formation ends */}
            <section className="py-28 bg-cover bg-bottom relative after:absolute after:z-10 after:inset-0 after:bg-primary after:bg-opacity-50" style={{ backgroundImage : "url(/images/ifim-gabon-pexels-matheus-bertelli-4558717.jpg)" }}>
                <Container className="relative z-20 grid xl:grid-cols-2 items-center min-h-[100vh]">
                    <div className="lg:pr-12 flex flex-col gap-y-4 text-justify  text-white">
                        <h1 className="lg:hidden text-center text-2xl  md:text-4xl font-bold text-secondary uppercase">MéTHODES D’éVALUATIONS DES CONNAISSANCES ET DES COMPéTENCES</h1>
                        <img src="/images/ifim-gabon-pexels-yan-krukov-8199656.jpg" alt="" className="lg:hidden h-72 w-full object-cover object-center rounded-md" />
                        <p className="">
                            Pour chaque Eléments Constitutifs des Unités d’Enseignement (ECUE), chaque étudiant est évalué sur la base :
                        </p>
                        <div className="text-secondary">
                            <i className="fas fa-star inline-flex justify-center items-center w-8 h-8 rounded-full bg-secondary text-white mr-2 " />
                            <span className="italic">
                                d’un contrôle continu (travail sur table, travail individuel de l’étudiant, travail de groupe, etc.) qui représente 40% de la note finale ;
                                d’un examen final qui constitue 60% de la note finale.
                            </span>
                        </div>
                        <p className="">
                            La nature des épreuves varie selon l’Enseignement. Plusieurs modalités, peuvent être combinées : <span className="italic text-secondary"> examen écrit, travail personnel ou de groupe, QCM, examen oral, mise en situation, présentation ou soutenance de travail de recherche documentaire.</span>
                        </p>
                        <p className="">
                            Le syllabus de chaque Elément Constitutif des Unités d’Enseignement (ECUE) précise les modalités pratiques de chacune des composantes de l’évaluation des étudiants.
                        </p>
                        <p className="">
                            L’étudiant qui a obtenu une moyenne inférieure à 10/20 pour une UE ou une notre inférieure à 07/20 pour une ou plusieurs ECUE doit passer un examen de rattrapage.
                        </p>
                        <p className="">
                            Après la phase théorique de la formation, chaque étudiant doit préparer et soutenir devant, un jury désigné par l’IFIM un rapport de stage ou un projet (professionnel ou entrepreneurial).
                        </p>
                        <p className=" text- italic text-secondary">
                            En cas de non-soutenance ou d’obtention d’une note inférieure à 12/20 au rapport de stage ou au projet (professionnel ou entrepreneurial), l’étudiant doit présenter un nouveau rapport de stage ou un nouveau projet (professionnel ou entrepreneurial) au plus tard un an après la date initiale de soutenance.
                        </p>
                    </div>
                
                    <div className="relative hidden md:block h-full w-full">
                        <img src="/images/ifim-gabon-pexels-yan-krukov-8199656.jpg" alt="" className="h-full w-full object-cover rounded-md" />
                        <h1 className="absolute top-1/2 -translate-y-1/2 text-center text-2xl  md:text-4xl font-bold text-secondary uppercase">MéTHODES D’éVALUATIONS DES CONNAISSANCES ET DES COMPéTENCES</h1>
                        
                    </div></Container>
            </section>
            {/* section objectif de la formation ends */}

            {/* section formations ends */}
            <section className="py-28" id="toutes-formations">
                <Container className="relative z-20 grid md:grid-cols-2 items-center min-h-[100vh]">
                    <div className="hidden md:block h-full w-full">
                        <img src="/images/ifm-gabon-african-females-business-meeting.jpg" alt="" className="h-full w-full object-cover rounded-md" />
                    </div>
                    <div className="lg:pl-12 text-justify ">
                        <h1 className="mb-2 2xl:mb-12 2xl:max-w-2xl text-4xl 2xl:text-6xl text-left 2xl:text-center font-bold text-primary">NOS FORMATIONS</h1>
                        <img src="/images/ifm-gabon-african-females-business-meeting.jpg" alt="" className="lg:hidden w-full object-cover rounded-md" />
                        
                        <p className=" hidden 2xl:block">
                            
                        </p>

                        <ul className="mt-4 list-none divide-y-8 divide-transparent">
                            {
                                FORMATIONS.map( ({name, slug}) => (
                                    <li key={`formation-${slug}`} className="">
                                        <Link to={`/formations/${slug}`} className="group flex items-start gap-2">
                                            <i className="text-xl 2xl:text-3xl text-primary group-hover:text-secondary fa-solid fa-square-check" />
                                            <span className="group-hover:text-primary group-hover:text-xl">{name}</span>
                                        </Link>
                                    </li>
                                )) 
                            }
                        </ul>
                    </div>
                </Container>
            </section>
            {/* section formations ends */}
        </>
    ) : !formationSelected ? <PageNotFound /> : <FormationDetailsPage formation={formationSelected as any} />

};
