import { ReactNode, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import GlobalContextProvider from "./contexts/global.context";
import ContactPage from "./pages/contact";
import FormationMainPage from "./pages/formation.main.page";
import HomePage from "./pages/home.page";
import MotDuFondateurPage from "./pages/mot-du-fondateur.page";
import PageNotFound from "./pages/page-not-found";

function ScrollToTop({children} : {children : ReactNode}){
  const location = useLocation()

  useEffect(() =>{
    if(location.hash){
        const el = document.getElementById(location.hash.substring(1))
        if(el){
          el.scrollIntoView({behavior: "smooth", block : 'nearest'})
        }else{
          window.scrollTo(0, 0);
        }
    }else{
      window.scrollTo(0, 0);
    }
  }, [location])

  return(
    <>
      {children}
    </>
  )
}

export default function App (){

  return (
    <GlobalContextProvider>
      <BrowserRouter>
          <ScrollToTop>
            <Header />
            <Routes>
              <Route path=""  element={<HomePage />} />
              <Route path="mot-du-fondateur"  element={<MotDuFondateurPage />} />
              <Route path="formations/*"  element={<FormationMainPage />} />
              <Route path="contact"  element={<ContactPage />} />
              
              <Route path='*' element={<PageNotFound />} />
            </Routes>
            <Footer />
          </ScrollToTop>
        </BrowserRouter>
    </GlobalContextProvider>
  )
}