import { Link } from "react-router-dom";
import { useEffect } from 'react';

export default function PageNotFound() {
    
    useEffect( () => {
        document.title = "IFIM Gabon | Page inexistante"
    }, [])
    return (
        <section className="py-20 min-h-[100vh] flex flex-col items-center justify-center">
            <img src="/images/ifim-gabon-404.png" className="block mx-auto w-[40rem] object-contain object-center" alt="" />
            <h1 className="-mt-8 text-center text-3xl text-primary">La page recherchée n'existe pas</h1>
            
            <Link to="/" className="px-8 py-2 text-white bg-primary font-semibold w-fit rounded-3xl mt-4">Accueil <i className="fas fa-home text-secondary" /></Link>
        </section>
    )
};
