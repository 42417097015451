type props = {
    children? : any
    className? : string
    style? : any
    fluid? : boolean
}
export default function Container({fluid, className, style, children} : props){

    return(
        <div className={`mx-2 ${!fluid ? "md:mx-20 lg:mx-32" : ""} ${className}`} style={style}>
            {children}
        </div>
    )
}